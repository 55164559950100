import React from "react";
import Layout from "../components/Layout";
import "../styles/index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
export default function Roadmap() {

  return (
     
    <Layout title="Product Road Map">
      <div className="container roadmap-container">
        <div className="row">
          <div className="col-md-12">
            <h1>Road Map</h1>
            <p>Checkout what we are working on in our next release.</p>
            <iframe className="airtable-embed" src="https://airtable.com/embed/shrpKjjPc6VrkVsJx?backgroundColor=red&layout=card" frameborder="0" onmousewheel="" width="100%" height="533"></iframe>
          </div>
        </div>
      </div>
    </Layout>

);
}
